import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/zhafirah.mp3'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/mela.scss'

let cmain = '#70ab7d'
let black = 'rgb(38,38,38)'
let slow = '#e2eddc'
let gold = '#9d7982'

let id = 'mela-wahyu'
let inisial_co = 'Wahyu'
let inisial_ce = 'Mela'

let lengkap_co = (<>Wahyu Nur Firdiansyah</>)
let lengkap_ce = (<>Melaidah Afi Samara</>)

let bapak_co = 'Bpk Suparno '
let ibu_co = 'Ibu Mariyani'
let bapak_ce = "Bpk Bambang Suliksono"
let ibu_ce = "Ibu  Qurrotul Aini "

let ig_co = "maswahyunf_"
let ig_ce = "melfisa"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/24/2021"


let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/bdyVpfoJMjaDULuY7"
let gcalendar = "https://calendar.google.com/event?action=TEMPLATE&tmeid=MTExOTFlaGoxcWMyMHBrNnJmMHFpMnFzaWYgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.661085105068!2d114.00215231477767!3d-7.719464994435708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zN8KwNDMnMTAuMSJTIDExNMKwMDAnMTUuNiJF!5e0!3m2!1sid!2sid!4v1607223099284!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "mela-wahyu", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slide = [
            "IMG_0308.jpg",
            "IMG_0333.jpg",
            "IMG_0490.jpg",
            "IMG_0562.jpg",
            "ym14.jpg",
            "ym15.jpg",
            "ym4.jpg",
            "ym6.jpg",
            "ym8.jpg",
            "ym9.jpg",
        ]
        let slider = []
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''
        let s=this.useQuery().get('s');
        if(s=="zxc"){
            s=1
        }
        if(s=="cxz"){
            s=2
        }
        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: slow
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent', color: cmain }}
                                style={{ position: 'relative' }}
                            >
                                <Container fluid id='g3-header' className='relative' style={{
                                    backgroundImage: `url('${pw(id,"bg-overlay.png")}')`,
                                    position:'fixed',top:0,left:0
                                }}></Container>
                                <Col xs={4} md={3} className="position-fixed p-0" style={{ top: 0, left: 0 }}>
                                    <img src={pw(id,"leaf.png")} className="w-100 w-fluid" />
                                </Col>
                                <Col xs={4} md={3} className="position-fixed p-0" style={{ top: 0, right: 0 }}>
                                    <img src={pw(id,"leaf.png")} className="w-100 w-fluid mirror" />
                                </Col>
                                <Col xs={4} md={3} className="position-fixed p-0" style={{ bottom: 0, left: 0 }}>
                                    <img src={pw(id,"leaf.png")} className="w-100 w-fluid flip180-m" />
                                </Col>
                                <Col xs={4} md={3} className="position-fixed p-0" style={{ bottom: 0, right: 0 }}>
                                    <img src={pw(id,"leaf.png")} className="w-100 w-fluid flip180 " />
                                </Col>
                                
                                <Container className="px-3 pt-5 ">
                                    <div style={{ position: 'relative' }} >

                                        <Item>
                                            <p style={{ fontFamily: 'Rouge Script, cursive', fontSize: '48px' }}>
                                                The Wedding
                                        </p>

                                        </Item>
                                        <Item>
                                            <p style={{ fontFamily: 'Rouge Script, cursive', fontSize: '64px' }}>
                                                Mela & Wahyu
                                        </p>

                                        </Item>
                                    </div>
                                    <Item>
                                        <p className="text-center"
                                            style={{
                                                fontFamily: 'Tinos,serif', fontSize: '16px',
                                                color: gold
                                            }}>
                                            Dan di antara tanda-tanda kekuasaan Allah, diciptakan-Nya
                                            untukmu pasangan hidup dari jenismu sendiri, supaya kamu memperoleh
                                            ketenangan hati dan dijadikan-Nya kasih sayang di antara kamu,
                                            Sesungguhnya yang demikian itu menjadi
                                            tanda-tanda kekuasaan Allah bagi
orang-orang berikir.<br /><br />
(QS. Ar-Rum: 21)
                                        </p>

                                    </Item>



                                </Container>

                                <Container fluid style={{ borderRadius: '15px' }} className="py-5">

                                    <Container id='sectiongold55' className="py-md-5 pb-5 pt-2" style={{ backgroundColor: slow, borderRadius: '10px', position: 'relative' }}>

                                        <Item>
                                            <p className="text-center px-3" style={{ fontFamily: 'Tinos,serif', fontSize: '16px', color: gold, fontStyle: 'normal' }}>
                                                <b>BISMILLAHIRRAHMANIRRAHIM</b><br /><br />
                                        Maha suci Engkau ya Allah yang menciptakan mahkluk-Mu berpasang-pasangan. </p>
                                        </Item>
                                        <Item>
                                            <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                                <div className='mr-lg-2'>
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', color: gold, fontFamily: 'Rouge Script, cursive' }}>
                                                            {inisial_ce}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center"
                                                            style={{ fontSize: '32px', fontFamily: 'Rouge Script, cursive', color: gold }}>
                                                            {lengkap_ce}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{
                                                            fontSize: '20px', fontStyle: 'normal',
                                                            color: gold
                                                        }}>
                                                            <b>Putri dari :</b><br />
                                                            {bapak_ce}  <br />
                    &<br />
                                                            {ibu_ce}
                                                        </p>
                                                    </Item>
                                                    <Item>

                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                    </Item>
                                                </div>
                                            </div>
                                            <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                                <div className='mr-lg-2'>
                                                    <Item>
                                                        <h1 style={{ fontSize: '72px', fontFamily: 'Rouge Script, cursive', color: gold }}>
                                                            {inisial_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <Col xs={6}>
                                                            <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                        </Col>
                                                    </Item>
                                                    <Item>
                                                        <h1 className="py-3 w-100 text-center" style={{
                                                            fontSize: '32px',
                                                            fontFamily: 'Rouge Script, cursive', color: gold
                                                        }}>
                                                            {lengkap_co}
                                                        </h1>
                                                    </Item>
                                                    <Item>
                                                        <p className='text-center' style={{ fontSize: '20px', fontStyle: 'normal', color: gold }}>
                                                            <b>Putra dari:</b><br />
                                                            {bapak_co}
                                                            <br />
                                                             &<br />
                                                            {ibu_co}
                                                        </p>
                                                    </Item>
                                                    <Item>
                                                        <img src={logoig} className='btn p-0'
                                                            onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                    </Item>
                                                </div>
                                            </div>
                                        </Item>
                                        <Item>
                                            <p className="w-100 text-center pt-3 pb-2 pb-md-5"
                                                style={{ color: gold, fontFamily: 'Tinos,serif', fontStyle: 'normal', fontSize: '18px' }}>
                                                <b style={{ color: cmain }}>AKAD	NIKAH</b><br />
                                            Senin, 18 Januari 2021<br />
                                            Pukul 07.00 WIB - Selesai<br />
                                            Kediaman Mempelai Wanita<br />
                                            Jl. Ronggowuni No. 71 Singosari - Malang<br />
                                            </p>
                                        </Item>
                                    </Container>
                                </Container>

                                <Container fluid className="text-center p-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="w-100 text-center"
                                            style={{ color: gold, fontFamily: 'Tinos,serif', fontStyle: 'normal', fontSize: '18px' }}>
                                            <b>Assalamualaikum Warahmatullahi Wabarakatuh</b><br />
                                            Dengan memohon rahmat dan ridho Allah SWT<br />
                                            kami bermaksud menyelenggarakan syukuran pernikahan<br />
                                            putra-putri kami tercinta<br />
                                            yang insya Allah dilaksanakan pada :<br />
                                        </p>
                                    </Item>
                                    <Item>
                                        <div className="px-2">
                                            <p className="text-center"
                                                style={{ color: cmain, fontFamily: 'Tinos,serif', fontStyle: 'normal', fontSize: '28px' }}>
                                                <b>MINGGU<br />
                                                    <div style={{ fontSize: '64px' }}>
                                                        24</div>
                                            JANUARI<br />
                                            2021</b>
                                            </p>
                                        </div>
                                        <div className="px-2">
                                            <p className="text-center"
                                                style={{ color: gold, fontFamily: 'Tinos,serif', fontStyle: 'normal', fontSize: '22px' }}>
                                                <b style={{ color: cmain }}>INTIMATE WEDDING</b><br />
                                                    {s==1?"10.00 - 11.00 WIB":""}
                                                    {s==2?"11.00 - 12.00 WIB":""}
                                                <br/>
                                                Widyagraha Hall - Universitas Widyagama Malang<br/>
Jl. Borobudur No. 35 Kota Malang
                                            </p>
                                        </div>
                                    </Item>
                                    <Item>
                                        <p className="w-100 text-center"
                                            style={{ color: gold, fontFamily: 'Tinos,serif', fontStyle: 'normal', fontSize: '18px' }}>

                                            Merupakan suatu kehormatan bagi kami<br />
apabila Bapak/Ibu/Saudara/i berkenan hadir<br />
dan memberikan doa restu kepada putra-putri kami.<br />
Atas kehadiran dan doa restu Bapak/Ibu/Saudara/i<br />
kami mengucapkan terima kasih.<br /><br />
                                            <b>Wassalamu'alaikum Warahmatullahi Wabarakatuh</b><br />

                                        </p>
                                    </Item>
                                    {/* <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                      </b>
                                        </p>
                                    </Item> */}

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset", "covid-green.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            “Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.”
                                                        <br />– Maya Angelou
                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

